import React from 'react';
import classnames from 'classnames';
import Props from './props';

const namespace = 'ui-homes-icon ui-homes-icon--gift';
const ICON_ID = 'gift_icon';

const IconGift = ({ className = null }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 9 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconGift.propTypes = Props.propTypes;

const Icon = React.memo(IconGift);
Icon.ICON_ID = ICON_ID;

export default Icon;
