import React from 'react';
import { string, oneOfType, object } from 'prop-types';

function Action({ label, target, htmlTarget = null, accesibilityText = null, ...props }) {
  return (
    <a href={target} target={htmlTarget} {...props}>
      {label}
      {accesibilityText && <span className="andes-visually-hidden">{accesibilityText}</span>}
    </a>
  );
}

/**
 * Prop types and defaults
 */
Action.propTypes = {
  target: string.isRequired,
  label: oneOfType([string, object]),
  htmlTarget: string,
  accesibilityText: string,
};

export default Action;
