import React from 'react';
import { string, object } from 'prop-types';
import Image from '../../../commons/image';

function Ribbon({ text, rating }) {
  return (
    <div className="partners-subscriptions__slide-ribbon">
      {text && (
        <span className="partners-subscriptions__slide-ribbon-partner-name">
          {text}
        </span>
      )}
      {rating && (
        <picture className="partners-subscriptions__slide-ribbon-rating">
          <Image lazyLoad {...rating} size="24px" />
        </picture>
      )}
    </div>
  );
}

Ribbon.propTypes = {
  text: string,
  rating: object,
};

export default Ribbon;
