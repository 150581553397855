import React from 'react';
import { string, shape, arrayOf, bool } from 'prop-types';
import { injectI18n } from 'nordic/i18n';
import { CarouselSnapped, CarouselSnappedSlide } from '@andes/carousel-snapped';
import classNames from 'classnames';

import Section from '../../../commons/section';
import Action from '../../../commons/action';
import withTracker from '../../../commons/with-tracker';
import SlideDesktop from './slide.desktop';

function LoyaltyPartnerSubscriptions({ loyalty_partner_card_info }) {
  const {
    title,
    type,
    elements,
    has_white_background,
    home_button: view_more_action,
    lowEnd,
    lazyLoad,
  } = loyalty_partner_card_info;

  const items = elements.map((element) => ({
    ...element,
    id: element.target,
    lazyLoad,
    lowEnd,
    only_element: elements.length === 1,
  }));

  return (
    <Section type={type} className="partners-subscriptions">
      <div
        className={classNames('row-container', {
          'row-container--white': has_white_background,
        })}
      >
        <div
          className={classNames('partners-subscriptions__body', {
            'partners-subscriptions__body-show-always-arrows':
              elements.length > 3,
          })}
        >
          <CarouselSnapped
            lazyload={false}
            slidesPerView={elements.length > 3 ? 3 : elements.length}
            srLabel={title}
            pagination={
              elements.length > 3 ? { mode: 'light', position: 'top' } : false
            }
            spacing={20}
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div className="section-header">
                <h2 className="section-header__title">{title}</h2>
                {view_more_action && <Action accesibilityText={title} {...view_more_action} />}
              </div>
            }
          >
            {items.map((item) => (
              <CarouselSnappedSlide key={item.id}>
                <SlideDesktop {...item} />
              </CarouselSnappedSlide>
            ))}
          </CarouselSnapped>
        </div>
      </div>
    </Section>
  );
}

LoyaltyPartnerSubscriptions.propTypes = {
  loyalty_partner_card_info: shape({
    title: string,
    type: string,
    elements: arrayOf(
      shape({
        target: string,
        title: shape({
          label: string,
        }),
        subtitle: shape({
          label: string,
        }),
        kicker: shape({
          label: string,
        }),
        top_pill: shape({
          title: shape({
            label: string,
          }),
          image: string,
        }),
        pill: shape({
          title: shape({
            label: string,
          }),
          image: string,
        }),
        picture: shape({
          src: string,
          alt: string,
        }),
        logo: shape({
          src: string,
          alt: string,
        }),
      }),
    ),
    home_button: shape({
      label: string,
      link: string,
    }),
    lowEnd: bool,
    lazyLoad: bool,
    has_white_background: bool,
  }),
};

export default injectI18n(withTracker(LoyaltyPartnerSubscriptions));
